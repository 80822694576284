import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql, Link } from "gatsby";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import "leaflet/dist/leaflet.css";
import "./Styles/Custom.scss";

import favicon from "../img/favicon.ico";
//import './all.sass'

const TemplateWrapper = ({ children, location, election }) => (
  <div>
    <Helmet>
      <html lang="en" />
      {/* <title>{data.site.siteMetadata.title}</title>
      <meta name="description" content={data.site.siteMetadata.description} />
      <meta name="theme-color" content="#fff" />
      <meta property="og:type" content="business.business" />
      <meta property="og:title" content={data.site.siteMetadata.title} />
      <meta property="og:url" content="/" />
      <meta property="og:image" content="/img/maxresdefault.jpg" /> */}

      <meta name="theme-color" content="#fff" />
      <meta property="og:type" content="business.business" />
      <meta
        property="og:image"
        content={`https://globelynx.com/img/maxresdefault.jpg`}
      />

      <meta
        name="google-site-verification"
        content="jyCKJEHaXKcQ7BnuXkhJyPmDrU8s6gZ4eInRL6B1nrk"
      />

      <link rel="icon" href={favicon} type="image/x-icon" />

      <link rel="shortcut icon" href={favicon} type="image/x-icon" />

      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.6.1/css/all.css"
        integrity="sha384-gfdkjb5BdAXd+lj+gudLWI+BXq4IuLW5IT+brZEZsLFm++aCMlF1V92rMkPaX4PP"
        crossorigin="anonymous"
      />

      <link
        href="https://fonts.googleapis.com/css?family=Roboto:300,400"
        rel="stylesheet"
      />

      <link
        rel="stylesheet"
        type="text/css"
        charset="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />

      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css"
      />

      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.4.0/dist/leaflet.css"
        integrity="sha512-puBpdR0798OZvTTbP4A8Ix/l+A4dHDD0DGqYW6RQ+9jxkRFclaxxQb/SJAWZfWAkuyeQUytO7+7N4QKrDh+drA=="
        crossorigin=""
      />

      {/* Global site tag (gtag.js) - Google Analytics  */}
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=UA-167069852-1`}
      ></script>

      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-157167489-2');
        `}
      </script>

      {/* <script>
        {
          `function init() {
          var vidDefer = document.getElementsByTagName('iframe');
          for (var i=0; i<vidDefer.length; {
          if(vidDefer[i].getAttribute('data-src')) {
                    vidDefer[i].setAttribute('src', vidDefer[i].getAttribute('data-src'));
          } } }
          window.onload = init;`
        }
      </script> */}

      <link
        rel="stylesheet"
        href="https://unpkg.com/react-quill@1.3.3/dist/quill.snow.css"
      />

      <script src="https://www.google.com/recaptcha/api.js"></script>

      <style>
        {`@media only screen and (max-width : 800px) {
 .modal-content {
   width: 80% !important;
 }
}

.grecaptcha-badge {
    visibility: hidden;
}`}
      </style>

      <script
        src="https://polyfill.io/v3/polyfill.min.js
"
      ></script>

      <script>
        {`if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}`}
      </script>

      <script
        type="text/javascript"
        src="https://app.99inbound.com/99inbound.js"
      ></script>
    </Helmet>

    <Link
      id="made-by"
      to="/contact"
      className={`${location === "dashboard" ? "is-display-none" : ""}`}
    >
      JOIN THE COMMUNITY
    </Link>
    <Navbar location={location} election={election} />
    <div>{children}</div>
    <Footer location={location} />
  </div>
);

export default TemplateWrapper;
