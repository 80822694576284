import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

import logo from "../img/logo.png";

import elexIMG from "../img/elex-315.png";

import { trackCustomEvent } from "gatsby-plugin-google-analytics";

import elasticsearch from "elasticsearch";
import _ from "lodash";

import Slider from "react-slick";

import "./Styles/Navbar.scss";

import SearchResults from "./SearchResults";

import VideoBackground from "./VideoBackground";

import MainMenu from "./MainMenu";

import axios from "axios";

import jwt from "jsonwebtoken";
import NotificationPopup from "./NotificationPopup";

// const connectionString =
//   "https://search-gl-experts-search-varfbfqqenkyp547xtgx3ttbwa.ap-southeast-2.es.amazonaws.com/";
// const _index = "experts";
// const _type = "_doc";

// let client = new elasticsearch.Client({
//   host: connectionString,
// });

let apiURL = "https://api.globelynx.com/api/";

let globalSearchQuery = "Aberdeen Asset Management PLC";
let globalSearchSize = 12;
let globalSearchFrom = 3;

let languageArrStatic = [
  { lang: "English", size: 1235 },
  { lang: "German", size: 77 },
  { lang: "French", size: 63 },
  { lang: "Italian", size: 27 },
  { lang: "Spanish", size: 25 },
  { lang: "English, German", size: 18 },
  { lang: "Chinese", size: 13 },
  { lang: "Russian", size: 12 },
  { lang: "Greek", size: 8 },
  { lang: "Turkish", size: 7 },
  { lang: "Arabic", size: 6 },
  { lang: "Dutch", size: 5 },
  { lang: "Swedish", size: 5 },
  { lang: "Danish", size: 4 },
  { lang: "Deutsch", size: 4 },
  { lang: "English, Mandarin", size: 4 },
  { lang: "English, Greek", size: 3 },
  { lang: "Japanese", size: 3 },
  { lang: "Portuguese", size: 3 },
  { lang: "Cantonese", size: 2 },
  { lang: "Cantonese, English, Mandarin", size: 2 },
  { lang: "English, Arabic", size: 2 },
  { lang: "English, Russian", size: 2 },
  { lang: "English, Spanish", size: 2 },
  { lang: "English, Welsh", size: 2 },
  { lang: "Mandarin", size: 2 },
  { lang: "Multiple", size: 2 },
  { lang: "Norwegian", size: 2 },
  { lang: "Polish", size: 2 },
  { lang: "Armenian", size: 1 },
  { lang: "Bulgarian", size: 1 },
  { lang: "Cantonese, English, Mandarin", size: 1 },
  { lang: "Czech", size: 1 },
  { lang: "English, Bahasa Malaysia", size: 1 },
  { lang: "English, Bengali, Hindi, Urdu", size: 1 },
  { lang: "English, Cantonese Chinese", size: 1 },
  { lang: "English, Danish", size: 1 },
  { lang: "English, Dari", size: 1 },
  { lang: "English, Farsi", size: 1 },
  { lang: "English, French, Dutch", size: 1 },
  { lang: "English, French, German, Spanish", size: 1 },
  { lang: "English, French, Italian", size: 1 },
  { lang: "English, German, Persian", size: 1 },
  { lang: "English, Hungarian", size: 1 },
  { lang: "English, Italian", size: 1 },
  { lang: "English, Polish", size: 1 },
  { lang: "English, Portuguese", size: 1 },
  { lang: "English, Portuguese, Spanish", size: 1 },
];

let sectorArrStatic = [
  { sector: "Education", size: 713 },
  { sector: "Finance", size: 415 },
  { sector: "Professional services", size: 60 },
  { sector: "Media", size: 35 },
  { sector: "Weather", size: 12 },
  { sector: "News", size: 10 },
  { sector: "Audit/professional services", size: 9 },
  { sector: "Lawyers", size: 9 },
  { sector: "Transport", size: 7 },
];

const newsTickSettings = {
  speed: 10000,
  autoplay: true,
  autoplaySpeed: 0,
  centerMode: true,
  cssEase: "linear",
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  infinite: true,
  initialSlide: 1,
  arrows: false,
  buttons: false,
};

let firstChange = 0;

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bannerData: [],
      searchResults: [],
      searchLanguages: [],
      langCount: 5,
      searchSectionViewClass: "is-display-none",
      loading: "is-loading",
      navHeader: "header",
      loadingHide: true,
      showSlider: "is-visibility-hidden",
      searchResultsSection: "",
      loginView: false,

      registerLoader: false,
      loginLoader: false,

      notification: false,
      notificationmsg: "",
      notificationstatus: "success",

      usersLoggedIn: "",
      usersLoggedEmail: "",
      register_username: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.tagSearch = this.tagSearch.bind(this);
    this.selectCheckBoxLanguage = this.selectCheckBoxLanguage.bind(this);
    this.selectCheckBoxSector = this.selectCheckBoxSector.bind(this);
    this.showMoreLang = this.showMoreLang.bind(this);
    this.openNav = this.openNav.bind(this);
    this.closeNav = this.closeNav.bind(this);
    this.openloginModal = this.openloginModal.bind(this);
    this.closeloginModal = this.closeloginModal.bind(this);
    this.loginExperts = this.loginExperts.bind(this);

    this.changeFormView = this.changeFormView.bind(this);

    this.handleRegisterSubmit = this.handleRegisterSubmit.bind(this);
    this.handleLoginRegister = this.handleLoginRegister.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);

    this.handleResetPasswordSubmit = this.handleResetPasswordSubmit.bind(this);

    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.resetPasswordModal = this.resetPasswordModal.bind(this);
    this.profileModalShow = this.profileModalShow.bind(this);
    this.logoutUser = this.logoutUser.bind(this);

    this.closeResetPasswordModal = this.closeResetPasswordModal.bind(this);
    this.closeLogoutModal = this.closeLogoutModal.bind(this);

    this.getBanner = this.getBanner.bind(this);
  }

  closeLogoutModal() {
    document.querySelector("#userModalContainer").style.display = "none";
  }

  closeResetPasswordModal() {
    document.querySelector("#resetPasswordModal").style.display = "none";
  }

  logoutUser() {
    localStorage.clear();
    window.location.reload();
  }

  profileModalShow() {
    document.querySelector("#userModalContainer").style.display = "block";
  }

  showNotification() {
    this.setState((prevState) => ({
      notification: !prevState.notification,
    }));

    setTimeout(
      function() {
        this.setState({ notification: false });
      }.bind(this),
      5000
    );
  }

  resetPasswordModal() {
    document.querySelector("#resetPasswordModal").style.display = "block";
    this.closeloginModal();
  }

  handleResetPasswordSubmit(e) {
    e.preventDefault();

    this.setState({
      fullscreenLoader: true,
    });

    axios
      .post(apiURL + "auth/resetpassword", {
        email: this.state.resetPassword,
      })
      .then((res) => {
        this.showNotification();
        this.setState({
          notificationmsg: "We've sent an email for verification.",
          notificationstatus: "success",
          fullscreenLoader: false,
        });
        this.closeResetPasswordModal();
      })
      .catch((err) => {
        // let res_obj = JSON.stringify(err);
        // res_obj = JSON.parse(res_obj)
        this.showNotification();
        this.setState({
          notificationmsg: "We can't find any accounts with this email address",
          notificationstatus: "error",
          fullscreenLoader: false,
        });
      });
  }

  handleLoginSubmit(e) {
    e.preventDefault();
    this.setState({
      fullscreenLoader: true,
      loginLoader: true,
    });
    axios
      .post(apiURL + "auth/login", {
        email: this.state.login_username,
        password: this.state.login_password,
      })
      .then((res) => {
        this.showNotification();
        this.setState({
          notificationmsg: "Login successfully",
          notificationstatus: "success",
          fullscreenLoader: false,
          loginLoader: false,
        });
        let token = res.data.token;
        jwt.verify(
          token,
          "0a6b944d-d2fb-46fc-a85e-0295c986cd9f",
          (err, decoded) => {
            if (decoded !== undefined) {
              localStorage.setItem("userToken", token);

              this.setState({
                usersLoggedIn: decoded.firstName + " " + decoded.lastName,
                usersLoggedEmail: decoded.email,
                isLoggedIn: true,
                loginLoader: false,
              });
            } else {
              this.setState({
                isLoggedIn: false,
                loginLoader: false,
              });
            }
            window.location.reload();
          }
        );
      })
      .catch((error) => {
        this.showNotification();
        this.setState({
          notificationmsg: "Incorrect username or password",
          notificationstatus: "error",
          fullscreenLoader: false,
          loginLoader: false,
        });
      });
  }

  handleResetPassword(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleLoginRegister(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  handleRegisterSubmit(e) {
    e.preventDefault();
    trackCustomEvent({
      category: "users",
      action: "signup",
      label: "signup",
      value: 1,
    });

    this.setState({
      registerLoader: true,
    });

    var username = this.state.register_username;
    let data = {
      email: username.toLowerCase(),
      password: this.state.register_password,
      firstName: this.state.register_firstName,
      lastName: this.state.register_lastName,
    };

    if (this.state.register_password == this.state.register_confirmpassword) {
      this.setState({
        fullscreenLoader: true,
      });
      axios
        .post(apiURL + "auth/register", data)
        .then((res) => {
          this.showNotification();
          this.setState({
            notificationmsg:
              "Thanks for making a Globelynx account! We’ve sent you a welcome email, but you can log in straight away.",
            notificationstatus: "success",
          });
          this.setState(
            {
              fullscreenLoader: false,
              registerLoader: false,
            },
            () => {
              setInterval(() => {
                window.location.reload();
              }, 4000);
            }
          );
        })
        .catch((error) => {
          // let res_obj = JSON.stringify(err);
          // res_obj = JSON.parse(res_obj)
          this.showNotification();
          this.setState({
            notificationmsg: error.message,
            notificationstatus: "error",
            fullscreenLoader: false,
            registerLoader: false,
          });
        });
    } else {
      this.showNotification();
      this.setState({
        notificationmsg: "Password did not match",
        notificationstatus: "error",
        registerLoader: false,
      });
    }
  }

  closeloginModal() {
    document.querySelector("#loginModalContainer").style.display = "none";
    document.querySelector(".experts-login").style.display = "none";
    // document.querySelector('.logins-btn').style.display = 'block'

    this.setState({
      loginView: false,
    });
  }

  loginExperts() {
    document.querySelector(".experts-login").style.display = "block";
    // document.querySelector('.logins-btn').style.display = 'none'
  }

  openloginModal() {
    document.querySelector("#loginModalContainer").style.display = "block";
    document.querySelector(".experts-login").style.display = "block";
  }

  changeFormView() {
    this.setState((prevState) => ({
      loginView: !prevState.loginView,
    }));
  }

  getBanner() {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(apiURL + "banner", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          bannerData: result,
        });
      })
      .catch((error) => {});
  }

  componentDidMount() {
    let token = localStorage.getItem("userToken");
    jwt.verify(
      token,
      "0a6b944d-d2fb-46fc-a85e-0295c986cd9f",
      (err, decoded) => {
        if (decoded !== undefined) {
          this.setState({
            usersLoggedIn: decoded.firstName + " " + decoded.lastName,
            usersLoggedEmail: decoded.email,
            isLoggedIn: true,
          });
        } else {
          this.setState({
            isLoggedIn: false,
          });
        }
      }
    );

    var url_string = window.location.href;
    var url = new URL(url_string);
    var expertsSearchFromOtherPage = url.searchParams.get("search");

    this.timeoutId = setTimeout(() => {
      if (this.props.location === "expertspage") {
        if (
          expertsSearchFromOtherPage !== null &&
          expertsSearchFromOtherPage !== "undefined"
        ) {
          document.querySelector(
            "#search-input"
          ).value = expertsSearchFromOtherPage;

          globalSearchQuery = expertsSearchFromOtherPage;
          this.setState({ loadingHide: false });
          window.addEventListener("scroll", this.handleScroll);
          this.elasticSearchCustomQuery();
        } else {
          globalSearchQuery = "";
          this.elasticSearchRandomCustomQuery();
        }
      } else {
        this.getBanner();

        axios
          .post(apiURL + "search/searchsizeandtext", {
            text: globalSearchQuery == "" ? " " : globalSearchQuery,
            size: globalSearchSize,
          })
          .then((res) => {
            this.setState({ searchResults: res.data.hits.hits, loading: "" });
          })
          .catch((err) => {
            // let res_obj = JSON.stringify(err);
            // res_obj = JSON.parse(res_obj)
          });

        // client
        //   .search({
        //     index: _index,
        //     type: _type,
        //     size: globalSearchSize,
        //     body: {
        //       query: {
        //         query_string: {
        //           query: globalSearchQuery + " *",
        //         },
        //       },
        //     },
        //   })
        //   .then(
        //     function (body) {
        //       this.setState({ searchResults: body.hits.hits, loading: "" });
        //     }.bind(this),
        //     function (error) {
        //       //console.trace(error.message);
        //     }
        //   );
      }
    }, 100);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    window.removeEventListener("scroll", this.handleScroll);
  }

  loginExperts() {
    document.querySelector(".experts-login").style.display = "block";
    document.querySelector(".logins-btn").style.display = "none";
    document.querySelector(".modal-content ").style.height = "40%";
  }

  openloginModal() {
    document.querySelector("#loginModalContainer").style.display = "block";
  }

  // closeloginModal() {
  //   document.querySelector("#loginModalContainer").style.display = "none";
  //   document.querySelector(".experts-login").style.display = "none";
  //   document.querySelector(".logins-btn").style.display = "block";
  //   document.querySelector(".modal-content ").style.height = "20%";
  // }

  handleChange(event) {
    firstChange = 1;

    if (firstChange === 1) {
      this.setState({ loadingHide: true });
      window.addEventListener("scroll", this.handleScroll);
    }

    if (this.props.location === "homepage") {
      globalSearchSize = 12;
    } else {
      globalSearchSize = 10;
    }

    if (event.target.value === "") {
      globalSearchQuery = "";
      this.elasticSearchRandomCustomQuery();
    } else {
      globalSearchQuery = event.target.value;
      this.elasticSearchCustomQuery();
    }
  }

  handleKeyPress(event) {
    if (event.key === "Enter") {
      if (this.props.location !== "expertspage") {
        window.location.href = `/experts/?search=${event.target.value}`;
      }
    }
  }

  handleScroll() {
    const windowpos = window.pageYOffset;
    const bottom_of_search = document
      .querySelector("#experts-search-container")
      .getBoundingClientRect();

    if (this.props.location === "expertspage") {
      if (windowpos > bottom_of_search.bottom) {
        globalSearchSize += 1;
        globalSearchFrom = globalSearchSize + 1;
        axios
          .post(apiURL + "search/scrollsearch", {
            text: globalSearchQuery == "" ? " " : globalSearchQuery,
            from: globalSearchFrom,
          })
          .then((res) => {
            if (globalSearchSize < res.data.hits.total) {
              var joined = this.state.searchResults.concat(
                res.data.hits.hits[0]
              );

              var indexed = this.state.searchResults.findIndex(
                (x) => x._id === res.data.hits.hits[0]._id
              );
              if (indexed > -1) {
                joined.splice(indexed, 1);
              }
              this.setState({ searchResults: joined });
            } else {
              this.setState({ loadingHide: true });
            }
          })
          .catch((err) => {
            // let res_obj = JSON.stringify(err);
            // res_obj = JSON.parse(res_obj)
          });

        // client
        //   .search({
        //     index: _index,
        //     type: _type,
        //     size: 1,
        //     from: globalSearchFrom,
        //     body: {
        //       query: {
        //         query_string: {
        //           query: globalSearchQuery,
        //         },
        //       },
        //     },
        //   })
        //   .then(
        //     function (body) {
        //       if (globalSearchSize < body.hits.total) {
        //         var joined = this.state.searchResults.concat(body.hits.hits[0]);

        //         var indexed = this.state.searchResults.findIndex(
        //           (x) => x._id === body.hits.hits[0]._id
        //         );
        //         if (indexed > -1) {
        //           joined.splice(indexed, 1);
        //         }
        //         this.setState({ searchResults: joined });
        //       } else {
        //         this.setState({ loadingHide: true });
        //       }
        //     }.bind(this),
        //     function (error) {
        //       //console.trace(error.message);
        //     }
        //   );
      }
    }
  }

  elasticSearchRandomCustomQuery() {
    axios
      .post(apiURL + "search/customsearch", {
        text: globalSearchQuery == "" ? " " : globalSearchQuery,
        size: globalSearchSize,
      })
      .then((res) => {
        if (
          res.data.hits.hits.length === 0 ||
          res.data.hits.hits === undefined
        ) {
          if (this.props.location === "expertspage") {
            document.querySelector("#no-search-results").innerHTML =
              "Sorry - none of our experts are listed under that term. Why not try searching using a different word or call us for help finding the right interviewee +44 (0)207 963 7021";

            this.setState({
              searchSectionViewClass: "is-display-none",
              // loadingHide: false,
            });
          }
        } else {
          if (this.props.location === "expertspage") {
            this.setState({
              searchResults: res.data.hits.hits,
              searchSectionViewClass: "",
              // loadingHide: false,
            });
          }
        }
      })
      .catch((err) => {
        // let res_obj = JSON.stringify(err);
        // res_obj = JSON.parse(res_obj)
      });

    // let customquery;
    // if (globalSearchQuery === "") {
    //   customquery = {
    //     query: {
    //       function_score: {
    //         query: { match_all: {} },
    //         random_score: {},
    //       },
    //     },
    //   };
    // } else {
    //   customquery = {
    //     query: {
    //       query_string: {
    //         query: globalSearchQuery + " *",
    //       },
    //     },
    //   };
    // }

    // client
    //   .search({
    //     index: _index,
    //     type: _type,
    //     size: globalSearchSize,
    //     body: customquery,
    //   })
    //   .then(
    //     function (body) {
    //       if (body.hits.hits.length === 0 || body.hits.hits === undefined) {
    //         if (this.props.location === "expertspage") {
    //           document.querySelector("#no-search-results").innerHTML =
    //             "Sorry - none of our experts are listed under that term. Why not try searching using a different word or call us for help finding the right interviewee +44 (0)207 963 7021";

    //           this.setState({
    //             searchSectionViewClass: "is-display-none",
    //             // loadingHide: false,
    //           });
    //         }
    //       } else {
    //         if (this.props.location === "expertspage") {
    //           this.setState({
    //             searchResults: body.hits.hits,
    //             searchSectionViewClass: "",
    //             // loadingHide: false,
    //           });
    //         }
    //       }
    //     }.bind(this),
    //     function (error) {
    //       //console.trace(error.message);
    //     }
    //   );
  }

  elasticSearchCustomQuery() {
    axios
      .post(apiURL + "search", {
        size: globalSearchSize,
        text: globalSearchQuery,
      })
      .then((res) => {
        if (
          res.data.hits.hits.length === 0 ||
          res.data.hits.hits === undefined
        ) {
          if (this.props.location === "expertspage") {
            document.querySelector("#no-search-results").innerHTML =
              "Sorry - none of our experts are listed under that term. Why not try searching using a different word or call us for help finding the right interviewee +44 (0)207 963 7021";
            this.setState({
              searchSectionViewClass: "is-display-none",
              loadingHide: false,
            });
          }
        } else {
          if (this.props.location === "expertspage") {
            this.setState({
              searchResults: res.data.hits.hits,
              searchSectionViewClass: "",
              loadingHide: false,
            });
          }
        }
      })
      .catch((err) => {
        // let res_obj = JSON.stringify(err);
        // res_obj = JSON.parse(res_obj)
      });

    // client
    //   .search({
    //     index: _index,
    //     type: _type,
    //     size: globalSearchSize,
    //     body: {
    //       query: {
    //         multi_match: {
    //           query: globalSearchQuery,
    //           fuzziness: "AUTO",
    //           operator: "and",
    //           fields: [
    //             "Name",
    //             "Email",
    //             "Company",
    //             "Country",
    //             "Subjects",
    //             "Languages",
    //             "category",
    //           ],
    //         },
    //       },
    //     },
    //   })
    //   .then(
    //     function (body) {
    //       if (body.hits.hits.length === 0 || body.hits.hits === undefined) {
    //         if (this.props.location === "expertspage") {
    //           document.querySelector("#no-search-results").innerHTML =
    //             "Sorry - none of our experts are listed under that term. Why not try searching using a different word or call us for help finding the right interviewee +44 (0)207 963 7021";
    //           this.setState({
    //             searchSectionViewClass: "is-display-none",
    //             loadingHide: false,
    //           });
    //         }
    //       } else {
    //         if (this.props.location === "expertspage") {
    //           this.setState({
    //             searchResults: body.hits.hits,
    //             searchSectionViewClass: "",
    //             loadingHide: false,
    //           });
    //         }
    //       }
    //     }.bind(this),
    //     function (error) {
    //       //console.trace(error.message);
    //     }
    //   );
  }

  showMenu() {
    if (this.state.navHeader === "header") {
      this.setState({ navHeader: "header responsive" });
    } else {
      this.setState({ navHeader: "header" });
    }
  }

  openNav() {
    document.getElementById("mobilenav").style.height = "100%";
  }

  closeNav() {
    document.getElementById("mobilenav").style.height = "0%";
  }

  tagSearch(e) {
    let tagBtnText = e.target.innerHTML;
    globalSearchQuery = tagBtnText;
    document.querySelector("#search-input").value = tagBtnText;

    this.elasticSearchCustomQuery();
  }

  selectCheckBoxLanguage(e) {
    var array = [];
    var checkboxes = document.querySelectorAll("input[type=checkbox]:checked");

    for (var i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
    }

    if (array.length) {
      this.setState({
        searchResultsSection: "is-display-none",
        loadingHide: false,
      });
      axios
        .post(apiURL + "search/searchLanguageOrSector", {
          text: array.join(),
          size: globalSearchSize,
          type: "Language",
        })
        .then((res) => {
          // this.setState({ searchResults: res.data.hits.hits, loading: "" });
          this.setState({
            searchResults: res.data.hits.hits,
            searchResultsSection: "",
            loadingHide: false,
          });
        })
        .catch((err) => {
          // let res_obj = JSON.stringify(err);
          // res_obj = JSON.parse(res_obj)
        });
    } else {
      globalSearchQuery = "";
      this.elasticSearchRandomCustomQuery();
    }
  }

  selectCheckBoxSector(e) {
    var array = [];
    var checkboxes = document.querySelectorAll("input[type=checkbox]:checked");

    for (var i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
    }

    if (array.length) {
      this.setState({
        searchResultsSection: "is-display-none",
        loadingHide: false,
      });

      axios
        .post(apiURL + "search/searchLanguageOrSector", {
          text: array.join(),
          size: globalSearchSize,
          type: "Sector",
        })
        .then((res) => {
          // this.setState({ searchResults: res.data.hits.hits, loading: "" });
          this.setState({
            searchResults: res.data.hits.hits,
            searchResultsSection: "",
            loadingHide: false,
          });
        })
        .catch((err) => {
          // let res_obj = JSON.stringify(err);
          // res_obj = JSON.parse(res_obj)
        });
    } else {
      globalSearchQuery = "";
      this.elasticSearchRandomCustomQuery();
    }
  }

  showMoreLang() {
    let btnHtml = document.querySelector("#show-more-lang").innerHTML;

    if (btnHtml === "+ Show more..") {
      let val = (this.state.langCount = 48);
      this.setState({ langCount: val });
      document.querySelector("#show-more-lang").innerHTML = "- Show fewer";
    } else {
      let val = this.state.langCount - 43;
      this.setState({ langCount: val });
      document.querySelector("#show-more-lang").innerHTML = "+ Show more..";
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allFeednewsUpdates {
              edges {
                node {
                  title
                  link
                  content
                }
              }
            }
          }
        `}
        render={(data) => (
          <>
            <div
              style={{ display: this.state.notification ? "block" : "none" }}
            >
              <NotificationPopup status={this.state.notificationstatus}>
                {this.state.notificationmsg}
              </NotificationPopup>
            </div>
            <MainMenu closeNav={this.closeNav} location={this.props.location} />
            {/* <div id="mobilenav" className="overlay">
						<a href="javascript:void(0)" className="closebtn" onClick={this.closeNav}>&times;</a>
						<div className="overlay-content">
							<Link activeStyle={{ color: '#13a7da' }} to="/">
								Home
							</Link>
							<Link activeStyle={{ color: '#13a7da' }} to="/about">
								About
							</Link>
							<Link activeStyle={{ color: '#13a7da' }} to="/experts">
								Experts
							</Link>
							<Link activeStyle={{ color: '#13a7da' }} to="/products">
								Services
							</Link>
							<Link activeStyle={{ color: '#13a7da' }} to="/cameras">
								Cameras
							</Link>
							<Link activeStyle={{ color: '#13a7da' }} to="/contact">
								Contact
							</Link>
						</div>
					</div> */}
            <div
              className={
                this.props.location === "homepage"
                  ? "hero-section-home video-background"
                  : "hero-section-not-home"
              }
              style={
                this.props.location === "dashboard" ? { display: "none" } : {}
              }
            >
              <div className={this.state.navHeader}>
                <div className="header-wrapper">
                  <a href="/" style={{ padding: 0, display: "inline" }}>
                    <img src={logo} className="logo" alt="Globelynx" />
                  </a>
                  {/* <div className="header-left">
                    <Link activeStyle={{ color: '#13a7da' }} to="/">
                      Home
                    </Link>
                    <Link activeStyle={{ color: '#13a7da' }} to="/about">
                      About
                    </Link>
                    <Link activeStyle={{ color: '#13a7da' }} to="/experts">
                      Experts
                    </Link>
                    <Link activeStyle={{ color: '#13a7da' }} to="/products">
                      Services
                    </Link>
                    <Link activeStyle={{ color: '#13a7da' }} to="/cameras">
                      Cameras
                    </Link>
                    <Link activeStyle={{ color: '#13a7da' }} to="/contact">
                      Contact
                    </Link>
                  </div> */}
                  <div className="header-right">
                    <div className="btn-group">
                      <button
                        className="booking-btn"
                        onClick={(e) =>
                          (window.location.href =
                            "http://bookings.globelynx.com/")
                        }
                      >
                        <i className="fa fa-user booking-btn-icon" />
                        <span style={{ fontSize: 12 }}>
                          <i className="fa fa-user " /> Make a booking
                        </span>
                      </button>

                      {/*  <span
                      id="loginModalId"  
                      onClick={this.openloginModal} 
                      style={{cursor: 'pointer'}} >
                        <i className="fa fa-sign-out-alt icon-round" />
                    </span> */}

                      {!this.state.isLoggedIn ? (
                        ""
                      ) : (
                        <button onClick={this.profileModalShow}>
                          <i className="fa fa-user" />{" "}
                          {this.state.usersLoggedIn}
                        </button>
                      )}

                      <button style={{ border: 0 }} onClick={this.openNav}>
                        {" "}
                        <i
                          className="fa fa-bars"
                          style={{ fontSize: "25px" }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <VideoBackground location={this.props.location} />
              {/* <div
                className={
                  this.props.location === 'homepage'
                    ? 'video-foreground'
                    : 'is-display-none'
                }
              >
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/5HDuNU8l5NY?modestbranding=1&mute=1&autoplay=1&controls=0&fs=0&loop=1&rel=0&showinfo=0&disablekb=1&playlist=5HDuNU8l5NY"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div> */}

              <section className="is-fullwidth">
                <div
                  className={`${
                    this.props.location === "homepage" ? "main-banner" : ""
                  }`}
                >
                  <div className="row">
                    <div
                      className={
                        this.props.location === "homepage"
                          ? "hero-container"
                          : "hero-container-not-homepage"
                      }
                    >
                      <h2
                        className="custom-heading"
                        style={{ textTransform: "initial" }}
                      >
                        Welcome To Globelynx
                      </h2>
                      <h2 style={{ textTransform: "initial" }}>
                        A global platform elevating brands by connecting experts
                        to broadcasters
                      </h2>

                      {this.state.bannerData.length != 0 &&
                      this.props.location === "homepage"
                        ? this.state.bannerData[0].bannerStatus !== "close" && (
                            <div
                              style={{
                                textAlign: "center",
                                marginTop: "50px",
                                marginBottom: "20px",
                              }}
                            >
                              <a href={this.state.bannerData[0].bannerLink}>
                                <img
                                  style={{
                                    opacity: 0.8,
                                  }}
                                  src={`https://s3-eu-west-1.amazonaws.com/globelynx-booking-portal-experts-compressed/public/${this.state.bannerData[0].bannerImage}`}
                                />
                              </a>
                            </div>
                          )
                        : ""}

                      {/* <h2>CONNECTING EXPERTS TO BROADCASTERS GLOBALLY</h2> */}
                      <div
                        className="inner-addon left-addon"
                        style={{
                          display:
                            this.props.location === "homepage" ? "none" : "",
                        }}
                      >
                        <i className="fa fa-search left-fa" />
                        <input
                          type="text"
                          placeholder="Search for experts..."
                          className="is-fullwidth"
                          id="search-input"
                          onChange={this.handleChange}
                          onKeyPress={this.handleKeyPress}
                          style={{ width: "100%" }}
                        />
                        {/*  <div  style={{color:'#4e5863', cursor:'pointer'}}>
                         <i className="fa fa-angle-right right-fa"/>
                         </div> */}
                      </div>
                      <div className="hero-popular-btn">
                        {/* 
													popular search bar removed on the new feedback :) 

													<span style={{color: '#fff'}}> Popular : </span>
												{
													tagsButton.map((val, key) => {
														return(
															<a
															className="button tags-button"
															key={key}
															onClick={this.tagSearch}
														>
															{val.sector}
														</a>
														)
													})
												} */}

                        {/* {this.state.searchResults.length !== 0
                            ? this.state.searchResults
                                .slice(0, 5)
                                .map((val, key) => {
                                  return (
                                    <a
                                      className="button tags-button"
                                      key={key}
                                      onClick={this.tagSearch}
                                    >
                                      {val._source.Title}
                                    </a>
                                  )
                                })
                            : this.state.searchResults
                                .slice(0, 5)
                                .map((val, key) => {
                                  return (
                                    <a
                                      className="button tags-button"
                                      key={key}
                                      onClick={this.tagSearch}
                                    >
                                      {val._source.Title}
                                    </a>
                                  )
                                })} */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div
              className={`info-box animated fadeIn delay-2s`}
              style={
                { display: "none" }
                // this.props.location === 'dashboard' ? { display: 'none' } : {}
              }
            >
              <div className="info-box-icon">
                <h4>NEWS</h4>
              </div>
              <div
                className="info-box-content"
                style={{ background: "#4e5863" }}
              >
                <Slider
                  {...newsTickSettings}
                  className=" animated fadeIn delay-2s"
                >
                  {_.map(data.allFeednewsUpdates.edges, (val, key) => {
                    return (
                      <span
                        key={key}
                        className="animated fadeIn delay-2s info-box-text has-text-ellipsis"
                        dangerouslySetInnerHTML={{ __html: val.node.content }}
                      />
                    );
                  })}
                </Slider>
              </div>
            </div>

            <div
              className={`is-fullwidth ${this.state.searchSectionViewClass}`}
              id="experts-search-container"
            >
              <div className="container-fluid">
                <div className="row has-padding has-text-centered">
                  <div className="three columns">
                    <div
                      className="container"
                      style={{
                        border: "1px solid #e8e8e8",
                        background: "#f7f5f6",
                        borderRadius: "5px",
                        textAlign: "left",
                        width: "100%",
                        padding: "20px",
                      }}
                    >
                      <h2>LANGUAGES</h2>
                      {_.map(
                        languageArrStatic.slice(0, this.state.langCount),
                        (val, key) => {
                          return (
                            <label className="checkbox" key={key}>
                              <input
                                type="checkbox"
                                className="checklist-language"
                                name="check_lang"
                                onChange={this.selectCheckBoxLanguage}
                                value={val.lang}
                              />
                              <span> {val.lang}</span>
                            </label>
                          );
                        }
                      )}
                      <p
                        onClick={this.showMoreLang}
                        style={{ cursor: "pointer" }}
                        id="show-more-lang"
                      >
                        + Show more..
                      </p>
                      <hr />
                      <h2>Topics</h2>
                      {_.map(sectorArrStatic, (val, key) => {
                        return (
                          <label className="checkbox" key={key}>
                            <input
                              type="checkbox"
                              onChange={this.selectCheckBoxSector}
                              value={val.sector}
                            />
                            <span> {val.sector}</span>
                          </label>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    className={`nine columns ${this.state.searchResultsSection}`}
                  >
                    <SearchResults
                      results={this.state.searchResults}
                      lastSearch={globalSearchQuery}
                    />
                  </div>
                  <div className="three columns" />
                  <div className="nine columns">
                    <div
                      className={`${
                        this.state.loadingHide ? "is-display-none" : ""
                      }`}
                    >
                      <div className={`lds-ellipsis `}>
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`is-fullwidth  ${
                this.state.searchSectionViewClass === "" ||
                this.props.location !== "expertspage"
                  ? "is-display-none"
                  : ""
              }`}
            >
              <div className="container-fluid">
                <div className="row has-padding has-text-centered">
                  <div className="twelve column">
                    <h2 id="no-search-results">
                      <div className={`lds-ellipsis `}>
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="loginModalContainer"
              className="modal"
              style={{ zIndex: "1111" }}
            >
              <div
                className="modal-content has-text-centered"
                style={{
                  height: "unset",
                  position: "relative",
                  marginTop: "30px",
                }}
              >
                <span className="close" onClick={this.closeloginModal}>
                  &times;
                </span>
                <h2 className="has-text-centered" id="textHeading">
                  {this.state.loginView ? "Create new account" : "Login"}
                </h2>

                <div
                  id="loginModal"
                  className="experts-login"
                  style={{ display: this.state.loginView ? "none" : "block" }}
                >
                  <form method="post" onSubmit={this.handleLoginSubmit}>
                    <div className="field">
                      <label className="label" htmlFor={"name"} />
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="login_username"
                          placeholder="Email"
                          required={true}
                          onChange={this.handleLoginRegister}
                        />
                        {/* <label className="form-control-placeholder">
                        Username
                      </label> */}
                      </div>
                    </div>

                    <div className="field">
                      <label className="label" htmlFor={"name"} />
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="password"
                          name="login_password"
                          placeholder="Password"
                          required={true}
                          onChange={this.handleLoginRegister}
                        />
                        {/* <label className="form-control-placeholder">
                        Password
                      </label> */}
                      </div>
                    </div>

                    <div className="field">
                      <button className="butto register-submit" type="submit">
                        {this.state.loginLoader ? (
                          <>
                            {" "}
                            Login <i className="fa fa-spinner fa-spin" />{" "}
                          </>
                        ) : (
                          `Login`
                        )}
                      </button>
                    </div>

                    <div className="field">
                      <p
                        style={{ fontSize: "13px", cursor: "pointer" }}
                        onClick={this.changeFormView}
                      >
                        {" "}
                        Create new account ? Sign up{" "}
                      </p>
                      <p
                        style={{ fontSize: "13px", cursor: "pointer" }}
                        onClick={this.resetPasswordModal}
                      >
                        Forgot password.{" "}
                      </p>
                    </div>
                  </form>
                </div>

                <div
                  id="registrationModal"
                  className="experts-login"
                  style={{ display: this.state.loginView ? "block" : "none" }}
                >
                  <form method="post" onSubmit={this.handleRegisterSubmit}>
                    <div className="field">
                      <label className="label" htmlFor={"name"} />
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Email"
                          name="register_username"
                          onChange={this.handleLoginRegister}
                          required={true}
                        />
                        {/* <label className="form-control-placeholder">
                    
                      </label> */}
                      </div>
                    </div>

                    <div className="field">
                      <label className="label" htmlFor={"name"} />
                      <div className="form-group">
                        <input
                          className="form-control"
                          placeholder="Password"
                          type="password"
                          name="register_password"
                          onChange={this.handleLoginRegister}
                          required={true}
                        />
                        {/* <label className="form-control-placeholder">
                        Password
                      </label> */}
                      </div>
                    </div>

                    <div className="field">
                      <label className="label" htmlFor={"name"} />
                      <div className="form-group">
                        <input
                          className="form-control"
                          placeholder="Confirm Password"
                          type="password"
                          name="register_confirmpassword"
                          onChange={this.handleLoginRegister}
                          required={true}
                        />
                        {/* <label className="form-control-placeholder">
                        Confirm Password
                      </label> */}
                      </div>
                    </div>

                    <div className="field">
                      <label className="label" htmlFor={"name"} />
                      <div className="form-group">
                        <input
                          className="form-control"
                          required={true}
                          placeholder="First name"
                          type="text"
                          name="register_firstName"
                          onChange={this.handleLoginRegister}
                        />
                        {/* <label className="form-control-placeholder">
                        First name
                      </label> */}
                      </div>
                    </div>

                    <div className="field">
                      <label className="label" htmlFor={"name"} />
                      <div className="form-group">
                        <input
                          className="form-control"
                          required={true}
                          type="text"
                          placeholder="Last name"
                          name="register_lastName"
                          onChange={this.handleLoginRegister}
                        />
                        {/* <label className="form-control-placeholder">
                        Last name
                      </label> */}
                      </div>
                    </div>

                    <div className="field">
                      <a
                        href="https://pamediagroup.com/privacy-policy/"
                        target="_blank"
                        style={{ color: "#3dbfd7" }}
                      >
                        <p
                          style={{
                            fontSize: 15,
                            color: "#3dbfd7",
                          }}
                        >
                          Terms and Conditions
                        </p>
                      </a>
                    </div>

                    <div className="field">
                      <button className="butto register-submit" type="submit">
                        {this.state.registerLoader ? (
                          <>
                            {" "}
                            Register <i className="fa fa-spinner fa-spin" />{" "}
                          </>
                        ) : (
                          `Register`
                        )}
                      </button>
                    </div>
                  </form>

                  <div className="field" id="footer-button">
                    <p
                      style={{ fontSize: "13px", cursor: "pointer" }}
                      onClick={this.changeFormView}
                    >
                      {" "}
                      Already have an account ? Login here{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="userModalContainer"
              className="modal"
              style={{ zIndex: "1111", display: "none" }}
            >
              <div
                className="modal-content has-text-centered"
                style={{
                  height: "unset",
                  position: "relative",
                  marginTop: "30px",
                }}
              >
                <span className="close" onClick={this.closeLogoutModal}>
                  &times;
                </span>
                <h2 className="has-text-centered">
                  {this.state.usersLoggedIn}
                </h2>

                <p className="has-text-centered">
                  To delete your account,{" "}
                  <a
                    href={`mailto:support@globelynx.com?subject=Website%20Account%20Removal%20&body=Can%20you%20please%20remove%20my%20account%20${this.state.usersLoggedEmail}`}
                    rel="EMAIL"
                  >
                    click here
                  </a>
                </p>

                <div className="field">
                  <button onClick={this.logoutUser}>Logout</button>
                </div>
              </div>
            </div>

            <div
              id="resetPasswordModal"
              className="modal"
              style={{ zIndex: "1111", display: "none" }}
            >
              <div
                className="modal-content has-text-centered"
                style={{
                  height: "unset",
                  position: "relative",
                  marginTop: "30px",
                }}
              >
                <span className="close" onClick={this.closeResetPasswordModal}>
                  &times;
                </span>
                <h2 className="has-text-centered">Reset Password</h2>

                <form method="post" onSubmit={this.handleResetPasswordSubmit}>
                  <div className="field">
                    <label className="label" htmlFor={"email"} />
                    <div className="form-group">
                      <input
                        className="form-control"
                        required={true}
                        type="text"
                        placeholder="Email"
                        name="resetPassword"
                        onChange={this.handleResetPassword}
                      />

                      <div className="field" style={{ marginTop: "10px" }}>
                        <div className="form-group">
                          <button type="submit">Send Email Confirmation</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      />
    );
  }
};

export default Navbar;
